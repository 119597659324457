import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../assets/privacy-policy.css"

const PrivacyPolicyPage = () => (
  <Layout navClass="w_menu" headerClass="transparent_header">
    <SEO title="Privacy Policy" />
    <section className="breadcrumb_area">
      <img
        className="breadcrumb_shap"
        src={require("../images/breadcrumb/banner_bg.png")}
        alt=""
      />
      <div className="container">
        <div className="breadcrumb_content text-center">
          <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
            Privacy Policy
          </h1>
        </div>
      </div>
    </section>
    <section className="agency_service_area bg_color pt-4">
      <div className="container">
        <div data-custom-class="body">
          <div
            className="MsoNormal"
            data-custom-class="title"
            style={{ textAlign: "left", lineHeight: "1.5" }}
            align="center"
          >
            <strong>
              <span style={{ lineHeight: "22.5px", fontSize: 26 }}>
                <bdt className="block-component" />
                PRIVACY POLICY
                <bdt className="statement-end-if-in-editor" />
              </span>
            </strong>
          </div>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <strong>
                <span data-custom-class="subtitle">
                  Last updated <bdt className="question">April 06, 2020</bdt>
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
            <br />
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Thank you for choosing to be part of our community at{" "}
                <bdt className="question">HookActions LLC</bdt>
                <bdt className="block-component" /> (“
                <bdt className="block-component" />
                <strong>Company</strong>
                <bdt className="statement-end-if-in-editor" />
                ”, “<strong>we</strong>”, “<strong>us</strong>”, or “
                <strong>our</strong>”). We are committed to protecting your
                personal information and your right to privacy. If you have any
                questions or concerns about our{" "}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">policy</span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                , or our practices with regards to your personal information,
                please contact us at{" "}
                <bdt className="question">support@hookactions.com</bdt>.
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                When you visit our <bdt className="block-component" />
                website{" "}
                <bdt className="question">
                  <a
                    href="https://hookactions.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-custom-class="link"
                  >
                    https://hookactions.com
                  </a>
                </bdt>
                ,<bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" /> and use our services, you
                trust us with your personal information. We take your privacy
                very seriously. In this{" "}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">privacy policy</span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                , we seek to explain to you in the clearest way possible what
                information we collect, how we use it and what rights you have
                in relation to it. We hope you take some time to read through it
                carefully, as it is important. If there are any terms in this{" "}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">privacy policy</span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </span>{" "}
                </span>
                that you do not agree with, please discontinue use of our{" "}
                <bdt className="block-component" />
                Sites
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />{" "}
                <bdt className="block-component" /> and our services.
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                This{" "}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">privacy policy</span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </span>{" "}
                </span>
                applies to all information collected through our{" "}
                <bdt className="block-component" />
                <bdt className="forloop-component" />
                <bdt className="question">website</bdt>
                <bdt className="forloop-component" /> (such as{" "}
                <bdt className="question">
                  <a
                    href="https://hookactions.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-custom-class="link"
                  >
                    https://hookactions.com
                  </a>
                </bdt>
                ), <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" /> and/or any related services,
                sales, marketing or events (we refer to them collectively in
                this{" "}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">privacy policy</span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </span>{" "}
                </span>
                as the "<strong>Services</strong>").
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <span data-custom-class="body_text">
                  Please read this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          privacy policy
                        </span>
                      </span>
                      <bdt className="statement-end-if-in-editor" />
                    </span>{" "}
                  </span>
                  carefully as it will help you make informed decisions about
                  sharing your personal information with us.
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <br />
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span style={{ fontSize: 19 }}>
                  <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infocollect">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </a>{" "}
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <bdt className="block-component" />
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infouse">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                2. HOW DO WE USE YOUR INFORMATION?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a data-custom-class="link" href="#infoshare">
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </a>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a data-custom-class="link" href="#whoshare">
                4. WHO WILL YOUR INFORMATION BE SHARED WITH?
              </a>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <bdt className="block-component" />
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#cookies">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </a>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <bdt className="statement-end-if-in-editor" />
            </span>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </span>
            <bdt className="block-component" />
            <bdt className="block-component" />
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#inforetain">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infosafe">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </a>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <bdt className="block-component" />
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infominors">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </span>
            </a>
            <bdt className="statement-end-if-in-editor" />
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#privacyrights">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                9. WHAT ARE YOUR PRIVACY RIGHTS?
                <bdt className="block-component" />
              </span>
            </a>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#databreach">
              <span style={{ color: "rgb(89, 89, 89)" }}>10. DATA BREACH</span>
            </a>
            <a data-custom-class="link" href="#privacyrights">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </a>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#DNT">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                11. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </a>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#caresidents">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </a>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#policyupdates">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                13. DO WE MAKE UPDATES TO THIS POLICY?
              </span>
            </a>
          </p>
          <p style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#contact">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
              </span>
            </a>
          </p>
          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <br />
            </span>
          </p>
          <p id="infocollect" style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span style={{ fontSize: 19 }}>
                  <span data-custom-class="heading_1">
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: 19 }}>
                <span data-custom-class="heading_1">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </p>
          <div style={{ lineHeight: "1.1" }}>
            <br />
          </div>
          <div>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_2">
                  Personal information you disclose to us
                </span>
              </strong>
            </span>
          </div>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <em>
                  <span data-custom-class="body_text">In Short:</span>
                </em>
              </strong>
              <span data-custom-class="body_text">
                <em>We collect personal information that you provide to us.</em>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text" style={{ fontSize: 15 }}>
                We collect personal information that you voluntarily provide to
                us when <bdt className="block-component" />
                registering at the <bdt className="block-component" />
                Services
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="statement-end-if-in-editor" /> expressing an
                interest in obtaining information about us or our products and
                services, when participating in activities on the{" "}
                <bdt className="block-component" />
                Services
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" /> or otherwise contacting us
              </span>
              <span data-custom-class="body_text">.</span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                The personal information that we collect depends on the context
                of your interactions with us and the{" "}
                <bdt className="block-component" />
                Services
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
                <bdt className="block-component" />, the choices you make and
                the products and features you use. The personal information we
                collect can include the following:
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <span data-custom-class="body_text">
                  Publicly Available Personal Information.
                </span>
              </strong>
              <span data-custom-class="body_text">
                {" "}
                We collect <bdt className="forloop-component" />
                <bdt className="question">
                  first name, maiden name, last name, and nickname
                </bdt>
                ; <bdt className="forloop-component" />
                <bdt className="question">email addresses</bdt>;{" "}
                <bdt className="forloop-component" />
                and other similar data.
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>{" "}
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <span data-custom-class="body_text">
                  Personal Information Provided by You.
                </span>
              </strong>
              <span data-custom-class="body_text">
                {" "}
                We collect <bdt className="forloop-component" />
                <bdt className="question">passwords</bdt>;{" "}
                <bdt className="forloop-component" />
                and other similar data.
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </span>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <span data-custom-class="body_text">Payment Data.</span>
              </strong>
              <span data-custom-class="body_text">
                {" "}
                We collect data necessary to process your payment if you make
                purchases, such as your payment instrument number (such as a
                credit card number), and the security code associated with your
                payment instrument. All payment data is stored by
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />{" "}
                    <bdt className="question">Stripe</bdt>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="forloop-component" />
                          </span>
                        </span>
                        . You may find their privacy policy link(s) here:
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="forloop-component" />{" "}
                          <bdt className="question">
                            <a
                              href="https://stripe.com/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                              data-custom-class="link"
                            >
                              https://stripe.com/privacy
                            </a>
                          </bdt>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="forloop-component" />
                              </span>
                              .
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                            <bdt className="block-component">
                              <span data-custom-class="body_text" />
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <strong>
              <span data-custom-class="heading_2">
                <br />
                <span style={{ fontSize: 16 }}>
                  Information automatically collected
                </span>
              </span>
            </strong>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <em>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">In Short: </span>
                  </span>{" "}
                </em>{" "}
              </strong>
              <em>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    Some information — such as IP address and/or browser and
                    device characteristics — is collected automatically when you
                    visit our <bdt className="block-component" />
                    Services
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />.
                  </span>
                </span>
              </em>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We automatically collect certain information when you visit,
                  use or navigate the <bdt className="block-component" />
                  Services
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our <bdt className="block-component" />
                  Services
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" /> and other technical
                  information. This information is primarily needed to maintain
                  the security and operation of our{" "}
                  <bdt className="block-component" />
                  Services
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />, and for our internal
                  analytics and reporting purposes.
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Like many businesses, we also collect information through
                  cookies and similar technologies.{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>Online Identifiers.</strong> We collect{" "}
                  <bdt className="forloop-component" />
                  <bdt className="question">
                    tools and protocols, such as IP (Internet Protocol)
                    addresses
                  </bdt>
                  ; <bdt className="forloop-component" />
                  <bdt className="question">
                    cookie identifiers, or others such as the ones used for
                    analytics and marketing
                  </bdt>
                  ; <bdt className="forloop-component" /> and other similar
                  data.
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
            <br />
          </p>
          <p id="infouse" style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span style={{ fontSize: 19 }}>
                  <span data-custom-class="heading_1">
                    2. HOW DO WE USE YOUR INFORMATION?
                  </span>
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <em>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">In Short:</span>
                  </span>{" "}
                </em>{" "}
              </strong>
              <span style={{ fontSize: 15 }}>
                <em>
                  <span data-custom-class="body_text">
                    We process your information for purposes based on legitimate
                    business interests, the fulfillment of our contract with
                    you, compliance with our legal obligations, and/or your
                    consent.
                  </span>
                </em>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  We use personal information collected via our{" "}
                  <bdt className="block-component" />
                  Services
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" /> for a variety of business
                  purposes described below. We process your personal information
                  for these purposes in reliance on our legitimate business
                  interests, in order to enter into or perform a contract with
                  you, with your consent, and/or for compliance with our legal
                  obligations. We indicate the specific processing grounds we
                  rely on next to each purpose listed below.
                </span>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  We use the information we collect or receive:
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To facilitate account creation and logon process.
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    {" "}
                    If you choose to link your account with us to a third party
                    account (such as your Google or Facebook account), we use
                    the information you allowed us to collect from those third
                    parties to facilitate account creation and logon process for
                    the performance of the contract.{" "}
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To send administrative information to you.{" "}
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    We may use your personal information to send you product,
                    service and new feature information and/or information about
                    changes to our terms, conditions, and policies.
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      Fulfill and manage your orders.
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    {" "}
                    We may use your information to fulfill and manage your
                    orders, payments, returns, and exchanges made through the{" "}
                    <bdt className="block-component" />
                    Services
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />.
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To protect our Services.
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    {" "}
                    We may use your information as part of our efforts to keep
                    our <bdt className="block-component" />
                    Services
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" /> safe and secure (for
                    example, for fraud monitoring and prevention).
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                        <bdt className="block-component" />
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To enforce our terms, conditions and policies for Business
                      Purposes, Legal Reasons and Contractual.
                    </span>
                  </strong>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To respond to legal requests and prevent harm.{" "}
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    If we receive a subpoena or other legal request, we may need
                    to inspect the data we hold to determine how to respond.
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      To manage
                                                                      user
                                                                      accounts
                                                                    </strong>
                                                                    . We may use
                                                                    your
                                                                    information
                                                                    for the
                                                                    purposes of
                                                                    managing our
                                                                    account and
                                                                    keeping it
                                                                    in working
                                                                    order.
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                          <bdt className="block-component" />
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <br />
                                                                  </span>
                                                                </span>{" "}
                                                              </span>{" "}
                                                            </span>{" "}
                                                          </span>{" "}
                                                        </span>{" "}
                                                      </span>{" "}
                                                    </span>{" "}
                                                  </span>{" "}
                                                </span>{" "}
                                              </span>{" "}
                                            </span>{" "}
                                          </span>{" "}
                                        </span>{" "}
                                      </span>{" "}
                                    </span>{" "}
                                  </span>{" "}
                                </span>{" "}
                              </span>{" "}
                            </span>{" "}
                          </span>{" "}
                        </span>{" "}
                      </span>{" "}
                    </span>{" "}
                  </span>{" "}
                </span>{" "}
              </span>
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      To deliver
                                                                      services
                                                                      to the
                                                                      user.
                                                                    </strong>{" "}
                                                                    We may use
                                                                    your
                                                                    information
                                                                    to provide
                                                                    you with the
                                                                    requested
                                                                    service.
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize: 15,
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15,
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span
                                                                                                                                    style={{
                                                                                                                                      fontSize: 15,
                                                                                                                                    }}
                                                                                                                                  >
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        color:
                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          fontSize: 15,
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            color:
                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              fontSize: 15,
                                                                                                                                            }}
                                                                                                                                          >
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                color:
                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  fontSize: 15,
                                                                                                                                                }}
                                                                                                                                              >
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    color:
                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span
                                                                                                                                                    style={{
                                                                                                                                                      fontSize: 15,
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize: 15,
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize: 15,
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      fontSize: 15,
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span
                                                                                                                                                                      style={{
                                                                                                                                                                        color:
                                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                                      }}
                                                                                                                                                                    >
                                                                                                                                                                      <span
                                                                                                                                                                        style={{
                                                                                                                                                                          fontSize: 15,
                                                                                                                                                                        }}
                                                                                                                                                                      >
                                                                                                                                                                        <span
                                                                                                                                                                          style={{
                                                                                                                                                                            color:
                                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                                          }}
                                                                                                                                                                        >
                                                                                                                                                                          <span
                                                                                                                                                                            style={{
                                                                                                                                                                              fontSize: 15,
                                                                                                                                                                            }}
                                                                                                                                                                          >
                                                                                                                                                                            <span
                                                                                                                                                                              style={{
                                                                                                                                                                                color:
                                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                                              }}
                                                                                                                                                                            >
                                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                              </span>
                                                                                                                                                                            </span>
                                                                                                                                                                          </span>
                                                                                                                                                                        </span>
                                                                                                                                                                      </span>
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <bdt className="block-component" />
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      To respond
                                                                      to user
                                                                      inquiries/offer
                                                                      support to
                                                                      users.
                                                                    </strong>{" "}
                                                                    We may use
                                                                    your
                                                                    information
                                                                    to respond
                                                                    to your
                                                                    inquiries
                                                                    and solve
                                                                    any
                                                                    potential
                                                                    issues you
                                                                    might have
                                                                    with the use
                                                                    of our
                                                                    Services.
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <strong>
                  <span data-custom-class="body_text">
                    For other Business Purposes.
                  </span>
                </strong>
                <span data-custom-class="body_text">
                  {" "}
                  We may use your information for other Business Purposes, such
                  as data analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and
                  improve our <bdt className="block-component" />
                  Services
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />, products, marketing and
                  your experience.{" "}
                </span>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We may use
                                                                      and store
                                                                      this
                                                                      information
                                                                      in
                                                                      aggregated
                                                                      and
                                                                      anonymized
                                                                      form so
                                                                      that it is
                                                                      not
                                                                      associated
                                                                      with
                                                                      individual
                                                                      end users
                                                                      and does
                                                                      not
                                                                      include
                                                                      personal
                                                                      information.
                                                                      We will
                                                                      not use
                                                                      identifiable
                                                                      personal
                                                                      information
                                                                      without
                                                                      your
                                                                      consent.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15,
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  color:
                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                  fontSize: 15,
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span
                                                                                                                                    style={{
                                                                                                                                      color:
                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                    }}
                                                                                                                                  >
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          color:
                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            fontSize: 15,
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              color:
                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                            }}
                                                                                                                                          >
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  color:
                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                }}
                                                                                                                                              >
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span
                                                                                                                                                    style={{
                                                                                                                                                      color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          color:
                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  color:
                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      color:
                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span
                                                                                                                                                                      style={{
                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                      }}
                                                                                                                                                                    >
                                                                                                                                                                      <span
                                                                                                                                                                        style={{
                                                                                                                                                                          color:
                                                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                                                        }}
                                                                                                                                                                      >
                                                                                                                                                                        <span
                                                                                                                                                                          style={{
                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                          }}
                                                                                                                                                                        >
                                                                                                                                                                          <span
                                                                                                                                                                            style={{
                                                                                                                                                                              color:
                                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                                            }}
                                                                                                                                                                          >
                                                                                                                                                                            <span
                                                                                                                                                                              style={{
                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                              }}
                                                                                                                                                                            >
                                                                                                                                                                              <span
                                                                                                                                                                                style={{
                                                                                                                                                                                  color:
                                                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                                                }}
                                                                                                                                                                              >
                                                                                                                                                                                <span
                                                                                                                                                                                  style={{
                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                  }}
                                                                                                                                                                                >
                                                                                                                                                                                  <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                      color:
                                                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                                                    }}
                                                                                                                                                                                  >
                                                                                                                                                                                    <span
                                                                                                                                                                                      style={{
                                                                                                                                                                                        color:
                                                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                      }}
                                                                                                                                                                                    >
                                                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                      </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                  </span>
                                                                                                                                                                                </span>
                                                                                                                                                                              </span>
                                                                                                                                                                            </span>
                                                                                                                                                                          </span>
                                                                                                                                                                        </span>
                                                                                                                                                                      </span>
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15,
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                fontSize: 15,
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
            <br />
          </p>
          <p id="infoshare" style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span style={{ fontSize: 19 }}>
                  <span data-custom-class="heading_1">
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </span>
                </span>
              </strong>
            </span>
          </p>
          <p style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <em>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">In Short:</span>
                  </span>{" "}
                </em>{" "}
              </strong>
              <span style={{ fontSize: 15 }}>
                <em>
                  <span data-custom-class="body_text">
                    We only share information with your consent, to comply with
                    laws, to provide you with services, to protect your rights,
                    or to fulfill business obligations.
                  </span>
                </em>
              </span>
            </span>
          </p>
          <div>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                We may process or share data based on the following legal basis:
              </span>
            </span>
          </div>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <strong>Consent:</strong> We may process your data if you have
                  given us specific consent to use your personal information in
                  a specific purpose.
                </span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <strong>Legitimate Interests:</strong> We may process your
                  data when it is reasonably necessary to achieve our legitimate
                  business interests.
                </span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <strong>Performance of a Contract: </strong>Where we have
                  entered into a contract with you, we may process your personal
                  information to fulfill the terms of our contract.
                </span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <strong>Legal Obligations:</strong> We may disclose your
                  information where we are legally required to do so in order to
                  comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to
                  public authorities to meet national security or law
                  enforcement requirements).
                </span>
                <br />
                <br />
              </span>
            </li>
            <li>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>Vital Interests:</strong> We may disclose your
                  information where we believe it is necessary to investigate,
                  prevent, or take action regarding potential violations of our
                  policies, suspected fraud, situations involving potential
                  threats to the safety of any person and illegal activities, or
                  as evidence in litigation in which we are involved.
                </span>
              </span>
            </li>
          </ul>
          <p style={{ fontSize: 15 }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  More specifically, we may need to process your data or share
                  your personal information in the following situations:
                </span>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      Vendors, Consultants and Other Third-Party Service
                      Providers.
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    {" "}
                    We may share your data with third party vendors, service
                    providers, contractors or agents who perform services for us
                    or on our behalf and require access to such information to
                    do that work. Examples include: payment processing, data
                    analysis, email delivery, hosting services, customer service
                    and marketing efforts. We may allow selected third parties
                    to use tracking technology on the{" "}
                    <bdt className="block-component" />
                    Services
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />, which will enable them
                    to collect data about how you interact with the{" "}
                    <bdt className="block-component" />
                    Services
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" /> over time. This
                    information may be used to, among other things, analyze and
                    track data, determine the popularity of certain content and
                    better understand online activity. Unless described in this
                    Policy, we do not share, sell, rent or trade any of your
                    information with third parties for their promotional
                    purposes. <bdt className="block-component" />
                    We have contracts in place with our data processors. This
                    means that they cannot do anything with your personal
                    information unless we have instructed them to do it. They
                    will not share your personal information with any
                    organisation apart from us. They will hold it securely and
                    retain it for the period we instruct.
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      Business Transfers.
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    {" "}
                    We may share or transfer your information in connection
                    with, or during negotiations of, any merger, sale of company
                    assets, financing, or acquisition of all or a portion of our
                    business to another company.
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <bdt className="block-component" />
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="whoshare" style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: 19 }}>
                    <span data-custom-class="heading_1">
                      4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
          </div>
          <div>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <strong>
                <em>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">In Short:</span>
                  </span>{" "}
                </em>{" "}
              </strong>
              <span style={{ fontSize: 15 }}>
                <em>
                  <span data-custom-class="body_text">
                    We only share information with the following third parties.
                  </span>
                </em>
              </span>
            </span>
          </div>
          <div>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15 }}> </span>{" "}
            </span>
          </div>
          <div>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  We only share and disclose your information with the following
                  third parties. We have categorized each party so that you may
                  be easily understand the purpose of our data collection and
                  processing practices. If we have processed your data based on
                  your consent and you wish to revoke your consent, please
                  contact us.
                </span>
              </span>
            </span>
          </div>
          <div>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </div>
          <ul>
            <li>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      Advertising, Direct Marketing, and Lead Generation
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="forloop-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                        <bdt className="question">HubSpot CRM</bdt>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="forloop-component" />
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>{" "}
                and <bdt className="question">HubSpot Lead Management</bdt>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="forloop-component" />
                <bdt className="statement-end-if-in-editor" />
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
            <div>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
            </div>
            <ul>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        Allow Users to Connect to their Third-Party Accounts
                      </strong>
                    </span>
                  </span>
                </span>
                <br />
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="forloop-component">
                      <span data-custom-class="body_text" />
                    </bdt>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                          <bdt className="question">Salesforce account</bdt>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />,
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          {" "}
                          <bdt className="question">Stripe account</bdt>
                          <bdt className="else-block" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>{" "}
                  and <bdt className="question">Calendly account</bdt>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="forloop-component" />
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
            </div>
            <ul>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>Cloud Computing Services</strong>
                    </span>
                  </span>
                </span>
                <br />
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="forloop-component">
                      <span data-custom-class="body_text" />
                    </bdt>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                          <bdt className="question">
                            Amazon Web Services (AWS)
                          </bdt>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="forloop-component" />
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
            </div>
            <ul>
              <li>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>Communicate and Chat with Users</strong>
                    </span>
                  </span>
                </span>
                <br />
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="forloop-component">
                      <span data-custom-class="body_text" />
                    </bdt>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                          <bdt className="question">HubSpot Email</bdt>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="forloop-component" />{" "}
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
              <div>
                <bdt className="block-component">
                  <span data-custom-class="body_text" />
                </bdt>
                <div>
                  <bdt className="block-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                  <div>
                    <bdt className="block-component">
                      <span data-custom-class="body_text" />
                    </bdt>
                  </div>
                  <ul>
                    <li>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>Invoice and Billing</strong>
                          </span>
                        </span>
                      </span>
                      <br />
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="forloop-component">
                            <span data-custom-class="body_text" />
                          </bdt>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="block-component" />
                                <bdt className="question">Stripe</bdt>
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                        <bdt className="forloop-component" />
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt className="block-component">
                      <span data-custom-class="body_text" />
                    </bdt>
                    <div>
                      <bdt className="block-component">
                        <span data-custom-class="body_text" />
                      </bdt>
                      <div>
                        <bdt className="block-component">
                          <span data-custom-class="body_text" />
                        </bdt>
                        <div>
                          <bdt className="block-component">
                            <span data-custom-class="body_text" />
                          </bdt>
                          <div>
                            <bdt className="block-component">
                              <span data-custom-class="body_text" />
                            </bdt>
                            <div>
                              <bdt className="block-component">
                                <span data-custom-class="body_text" />
                              </bdt>
                            </div>
                            <ul>
                              <li>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>Website Hosting</strong>
                                    </span>
                                  </span>
                                </span>
                                <br />
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="forloop-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <bdt className="block-component" />
                                          <bdt className="question">
                                            Netlify
                                          </bdt>
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <bdt className="forloop-component" />
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </li>
                            </ul>
                            <div>
                              <bdt className="block-component">
                                <span data-custom-class="body_text" />
                              </bdt>
                            </div>
                            <ul>
                              <li>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>
                                        Website Performance Monitoring
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                                <br />
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="forloop-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <bdt className="block-component" />
                                          <bdt className="question">Sentry</bdt>
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <bdt className="forloop-component" />
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </li>
                            </ul>
                            <div>
                              <bdt className="block-component">
                                <span data-custom-class="body_text" />
                              </bdt>
                              <div>
                                <span data-custom-class="body_text" />
                                <span data-custom-class="body_text" />
                                <span data-custom-class="body_text" />
                                <span data-custom-class="body_text" />
                                <span data-custom-class="body_text" />
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <bdt className="block-component">
                                            <span data-custom-class="heading_1" />
                                          </bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <p style={{ fontSize: 15 }}>
                                <br />
                              </p>
                              <div>
                                <span
                                  id="cookies"
                                  style={{ color: "rgb(0, 0, 0)" }}
                                >
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        5. DO WE USE COOKIES AND OTHER TRACKING
                                        TECHNOLOGIES?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </div>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <em>
                                      <span data-custom-class="body_text">
                                        We may use cookies and other tracking
                                        technologies to collect and store your
                                        information.
                                      </span>
                                    </em>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    We may use cookies and similar tracking
                                    technologies (like web beacons and pixels)
                                    to access or store information. Specific
                                    information about how we use such
                                    technologies and how you can refuse certain
                                    cookies is set out in our Cookie Policy
                                    <bdt className="block-component" />.
                                  </span>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                              <bdt className="block-component" />
                                            </span>
                                            <bdt className="block-component">
                                              <span data-custom-class="body_text" />
                                            </bdt>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <br />
                                </span>
                              </p>
                              <p id="inforetain" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        6. HOW LONG DO WE KEEP YOUR INFORMATION?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <em>
                                      <span data-custom-class="body_text">
                                        We keep your information for as long as
                                        necessary to fulfill the purposes
                                        outlined in this{" "}
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                privacy policy
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>{" "}
                                        </span>
                                        unless otherwise required by law.
                                      </span>
                                    </em>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      We will only keep your personal
                                      information for as long as it is necessary
                                      for the purposes set out in this{" "}
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="block-component" />
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              privacy policy
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                      , unless a longer retention period is
                                      required or permitted by law (such as tax,
                                      accounting or other legal requirements).
                                      No purpose in this policy will require us
                                      keeping your personal information for
                                      longer than{" "}
                                      <bdt className="block-component" />
                                      <bdt className="question">
                                        90 days past the termination of the
                                        user's account
                                      </bdt>
                                      <bdt className="else-block" />.
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    When we have no ongoing legitimate business
                                    need to process your personal information,
                                    we will either delete or anonymize it, or,
                                    if this is not possible (for example,
                                    because your personal information has been
                                    stored in backup archives), then we will
                                    securely store your personal information and
                                    isolate it from any further processing until
                                    deletion is possible.
                                  </span>
                                </span>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="block-component" />
                                </span>
                              </p>
                              <div style={{ lineHeight: "1.5" }}>
                                <br />
                              </div>
                              <p id="infosafe" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <em>
                                      <span data-custom-class="body_text">
                                        We aim to protect your personal
                                        information through a system of
                                        organizational and technical security
                                        measures.
                                      </span>
                                    </em>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    We have implemented appropriate technical
                                    and organizational security measures
                                    designed to protect the security of any
                                    personal information we process. However,
                                    please also remember that we cannot
                                    guarantee that the internet itself is 100%
                                    secure. Although we will do our best to
                                    protect your personal information,
                                    transmission of personal information to and
                                    from our <bdt className="block-component" />
                                    Services
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" /> is at
                                    your own risk. You should only access the
                                    services within a secure environment.
                                  </span>
                                </span>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <br />
                                </span>
                              </p>
                              <p id="infominors" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        8. DO WE COLLECT INFORMATION FROM
                                        MINORS?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <em>
                                      <span data-custom-class="body_text">
                                        We do not knowingly collect data from or
                                        market to children under 18 years of
                                        age.
                                      </span>
                                    </em>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    We do not knowingly solicit data from or
                                    market to children under 18 years of age. By
                                    using the{" "}
                                    <bdt className="block-component" />
                                    Services
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />, you
                                    represent that you are at least 18 or that
                                    you are the parent or guardian of such a
                                    minor and consent to such minor dependent’s
                                    use of the{" "}
                                    <bdt className="block-component" />
                                    Services
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />. If we
                                    learn that personal information from users
                                    less than 18 years of age has been
                                    collected, we will deactivate the account
                                    and take reasonable measures to promptly
                                    delete such data from our records. If you
                                    become aware of any data we have collected
                                    from children under age 18, please contact
                                    us at <bdt className="block-component" />
                                    <bdt className="question">
                                      ewilson@hookactions.com
                                    </bdt>
                                    <bdt className="statement-end-if-in-editor" />
                                    .
                                  </span>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <br />
                                </span>
                              </p>
                              <p id="privacyrights" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        9. WHAT ARE YOUR PRIVACY RIGHTS?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <em>
                                        <bdt className="block-component" />
                                        In some regions, such as the European
                                        Economic Area, you have rights that
                                        allow you greater access to and control
                                        over your personal information.{" "}
                                        <bdt className="statement-end-if-in-editor" />
                                        You may review, change, or terminate
                                        your account at any time.
                                      </em>
                                    </span>
                                    <bdt className="block-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      In some regions (like the European
                                      Economic Area), you have certain rights
                                      under applicable data protection laws.
                                      These may include the right (i) to request
                                      access and obtain a copy of your personal
                                      information, (ii) to request rectification
                                      or erasure; (iii) to restrict the
                                      processing of your personal information;
                                      and (iv) if applicable, to data
                                      portability. In certain circumstances, you
                                      may also have the right to object to the
                                      processing of your personal information.
                                      To make such a request, please use the
                                    </span>
                                  </span>{" "}
                                </span>
                                <span data-custom-class="body_text">
                                  <a data-custom-class="link" href="#contact">
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(48, 48, 241)" }}
                                      >
                                        contact details
                                      </span>
                                    </span>
                                  </a>
                                </span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      {" "}
                                      provided below. We will consider and act
                                      upon any request in accordance with
                                      applicable data protection laws.
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      If we are relying on your consent to
                                      process your personal information, you
                                      have the right to withdraw your consent at
                                      any time. Please note however that this
                                      will not affect the lawfulness of the
                                      processing before its withdrawal.
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      If you are resident in the European
                                      Economic Area and you believe we are
                                      unlawfully processing your personal
                                      information, you also have the right to
                                      complain to your local data protection
                                      supervisory authority. You can find their
                                      contact details here:
                                    </span>
                                  </span>{" "}
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(48, 48, 241)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <a
                                        data-custom-class="link"
                                        href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                      </a>
                                      .
                                    </span>
                                  </span>
                                </span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="block-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      If you have questions or comments about
                                      your privacy rights, you may email us at{" "}
                                      <bdt className="question">
                                        support@hookactions.com
                                      </bdt>
                                      .
                                    </span>
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </p>
                              <div style={{ lineHeight: "1.1" }}>
                                <br />
                              </div>
                              <div>
                                <span style={{ fontSize: 16 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_2">
                                        Account Information
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </div>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      If you would at any time like to review or
                                      change the information in your account or
                                      terminate your account, you can:
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                {" "}
                                ■{" "}
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="question">
                                        Log into your account settings and
                                        update your user account.
                                      </bdt>{" "}
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                {" "}
                                ■{" "}
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="question">
                                        Contact us using the contact information
                                        provided.
                                      </bdt>{" "}
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      Upon your request to terminate your
                                      account, we will deactivate or delete your
                                      account and information from our active
                                      databases. However, some information may
                                      be retained in our files to prevent fraud,
                                      troubleshoot problems, assist with any
                                      investigations, enforce our Terms of Use
                                      and/or comply with legal requirements.
                                    </span>
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <bdt className="statement-end-if-in-editor" />
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <strong>
                                      <u>
                                        <span data-custom-class="body_text">
                                          Cookies and similar technologies:
                                        </span>
                                      </u>{" "}
                                    </strong>
                                    <span data-custom-class="body_text">
                                      Most Web browsers are set to accept
                                      cookies by default. If you prefer, you can
                                      usually choose to set your browser to
                                      remove cookies and to reject cookies. If
                                      you choose to remove cookies or reject
                                      cookies, this could affect certain
                                      features or services of our{" "}
                                      <bdt className="block-component" />
                                      Services
                                      <bdt className="statement-end-if-in-editor" />
                                      <bdt className="block-component" />
                                      <bdt className="block-component" />. To
                                      opt-out of interest-based advertising by
                                      advertisers on our{" "}
                                      <bdt className="block-component" />
                                      Services
                                      <bdt className="statement-end-if-in-editor" />
                                      <bdt className="block-component" />
                                    </span>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" /> visit
                                    </span>
                                  </span>{" "}
                                </span>
                                <span style={{ color: "rgb(48, 48, 241)" }}>
                                  <span data-custom-class="body_text">
                                    <a
                                      data-custom-class="link"
                                      href="http://www.aboutads.info/choices/"
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span style={{ fontSize: 15 }}>
                                        http://www.aboutads.info/choices/
                                      </span>
                                    </a>
                                  </span>
                                </span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      . <bdt className="block-component" />
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <strong>
                                      <u>
                                        <span data-custom-class="body_text">
                                          Opting out of email marketing:
                                        </span>
                                      </u>{" "}
                                    </strong>
                                    <span data-custom-class="body_text">
                                      You can unsubscribe from our marketing
                                      email list at any time by clicking on the
                                      unsubscribe link in the emails that we
                                      send or by contacting us using the details
                                      provided below. You will then be removed
                                      from the marketing email list – however,
                                      we will still need to send you
                                      service-related emails that are necessary
                                      for the administration and use of your
                                      account. To otherwise opt-out, you may:
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                {" "}
                                ■{" "}
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  {" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="question">
                                        Access your account settings and update
                                        preferences.
                                      </bdt>{" "}
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                {" "}
                                ■{" "}
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  {" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="question">
                                        Contact us using the contact information
                                        provided.
                                      </bdt>{" "}
                                      <bdt className="forloop-component" />
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <span style={{ fontSize: 15 }}>
                                              <bdt className="statement-end-if-in-editor">
                                                <bdt className="block-component" />
                                              </bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <br />
                              </p>
                              <p id="databreach" style={{ fontSize: 15 }}>
                                <strong>
                                  <span
                                    data-custom-class="heading_1"
                                    style={{ fontSize: 19 }}
                                  >
                                    10. DATA BREACH
                                  </span>
                                </strong>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  data-custom-class="body_text"
                                  style={{ fontSize: 15 }}
                                >
                                  A privacy breach occurs when there is
                                  unauthorized access to or collection, use,
                                  disclosure or disposal of personal
                                  information. You will be notified about data
                                  breaches when{" "}
                                  <bdt className="question">
                                    HookActions LLC
                                  </bdt>{" "}
                                  believes you are likely to be at risk or
                                  serious harm. For example, a data breach may
                                  be likely to result in serious financial harm
                                  or harm to your mental or physical well-being.
                                  In the event that{" "}
                                  <bdt className="question">
                                    HookActions LLC
                                  </bdt>{" "}
                                  becomes aware of a security breach which has
                                  resulted or may result in unauthorized access,
                                  use or disclosure of personal information{" "}
                                  <bdt className="question">
                                    HookActions LLC
                                  </bdt>{" "}
                                  will promptly investigate the matter and
                                  notify the applicable Supervisory Authority
                                  not later than 72 hours after having become
                                  aware of it, unless the personal data breach
                                  is unlikely to result in a risk to the rights
                                  and freedoms of natural persons.
                                </span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor">
                                            <bdt className="statement-end-if-in-editor" />
                                          </bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <br />
                              </p>
                              <p id="DNT" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        11. CONTROLS FOR DO-NOT-TRACK FEATURES
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      Most web browsers and some mobile
                                      operating systems and mobile applications
                                      include a Do-Not-Track (“DNT”) feature or
                                      setting you can activate to signal your
                                      privacy preference not to have data about
                                      your online browsing activities monitored
                                      and collected. No uniform technology
                                      standard for recognizing and implementing
                                      DNT signals has been finalized. As such,
                                      we do not currently respond to DNT browser
                                      signals or any other mechanism that
                                      automatically communicates your choice not
                                      to be tracked online. If a standard for
                                      online tracking is adopted that we must
                                      follow in the future, we will inform you
                                      about that practice in a revised version
                                      of this{" "}
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="block-component" />
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              privacy policy
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                      .
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <br />
                              </p>
                              <p id="caresidents" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        12. DO CALIFORNIA RESIDENTS HAVE
                                        SPECIFIC PRIVACY RIGHTS?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <em>
                                      <span data-custom-class="body_text">
                                        Yes, if you are a resident of
                                        California, you are granted specific
                                        rights regarding access to your personal
                                        information.
                                      </span>
                                    </em>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      California Civil Code Section 1798.83,
                                      also known as the “Shine The Light” law,
                                      permits our users who are California
                                      residents to request and obtain from us,
                                      once a year and free of charge,
                                      information about categories of personal
                                      information (if any) we disclosed to third
                                      parties for direct marketing purposes and
                                      the names and addresses of all third
                                      parties with which we shared personal
                                      information in the immediately preceding
                                      calendar year. If you are a California
                                      resident and would like to make such a
                                      request, please submit your request in
                                      writing to us using the contact
                                      information provided below.
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    If you are under 18 years of age, reside in
                                    California, and have a registered account
                                    with the <bdt className="block-component" />
                                    Services
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />, you
                                    have the right to request removal of
                                    unwanted data that you publicly post on the{" "}
                                    <bdt className="block-component" />
                                    Services
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />. To
                                    request removal of such data, please contact
                                    us using the contact information provided
                                    below, and include the email address
                                    associated with your account and a statement
                                    that you reside in California. We will make
                                    sure the data is not publicly displayed on
                                    the <bdt className="block-component" />
                                    Services
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />, but
                                    please be aware that the data may not be
                                    completely or comprehensively removed from
                                    our systems.
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <br />
                                </span>
                              </p>
                              <p
                                id="policyupdates"
                                style={{ fontSize: 15, lineHeight: "1.5" }}
                              >
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        13. DO WE MAKE UPDATES TO THIS POLICY?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <strong>
                                    <em>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          In Short:
                                        </span>
                                      </span>{" "}
                                    </em>{" "}
                                  </strong>
                                  <span style={{ fontSize: 15 }}>
                                    <em>
                                      <span data-custom-class="body_text">
                                        Yes, we will update this policy as
                                        necessary to stay compliant with
                                        relevant laws.
                                      </span>
                                    </em>
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    We may update this{" "}
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            privacy policy
                                          </span>
                                        </span>
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>{" "}
                                    </span>
                                    from time to time. The updated version will
                                    be indicated by an updated “Revised” date
                                    and the updated version will be effective as
                                    soon as it is accessible. If we make
                                    material changes to this{" "}
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            privacy policy
                                          </span>
                                        </span>
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                    , we may notify you either by prominently
                                    posting a notice of such changes or by
                                    directly sending you a notification. We
                                    encourage you to review this{" "}
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            privacy policy
                                          </span>
                                        </span>
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>{" "}
                                    </span>
                                    frequently to be informed of how we are
                                    protecting your information.
                                  </span>
                                </span>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <br />
                                </span>
                              </p>
                              <p id="contact" style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span style={{ fontSize: 19 }}>
                                      <span data-custom-class="heading_1">
                                        14. HOW CAN YOU CONTACT US ABOUT THIS
                                        POLICY?
                                      </span>
                                    </span>
                                  </strong>
                                </span>
                              </p>
                              <p style={{ fontSize: 15 }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    If you have questions or comments about this
                                    policy, you may{" "}
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    contact our Data Protection Officer (DPO),{" "}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">
                                      Elijah Wilson
                                    </bdt>
                                    , by email at{" "}
                                    <bdt className="question">
                                      ewilson@hookactions.com
                                    </bdt>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  ,<bdt className="block-component" />
                                </span>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="else-block" /> or by post
                                    to:
                                  </span>
                                </span>
                              </p>
                              <div>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="question">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        HookActions LLC
                                      </span>
                                    </span>{" "}
                                  </bdt>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="question">
                                          Elijah Wilson
                                        </bdt>
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="question">
                                          615 S Saginaw St
                                        </bdt>
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="question">
                                          Suite 4008
                                        </bdt>
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="question">Flint</bdt>
                                      <bdt className="block-component" />
                                      <bdt className="block-component" />,{" "}
                                      <bdt className="question">MI</bdt>
                                      <bdt className="statement-end-if-in-editor" />
                                      <bdt className="block-component" />
                                    </span>
                                    <bdt className="block-component" />{" "}
                                    <bdt className="question">48502</bdt>
                                    <bdt className="statement-end-if-in-editor" />
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="block-component" />{" "}
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <bdt className="question">
                                          United States
                                        </bdt>
                                      </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                        <bdt className="else-block">
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </bdt>
                                      </span>
                                    </span>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt className="else-block" />
                                      <bdt className="else-block" />
                                    </span>
                                  </span>
                                </span>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                                <bdt className="block-component" />
                              </div>
                              <p
                                id="contact"
                                style={{ fontSize: 15, lineHeight: "1.5" }}
                              >
                                <br />
                              </p>
                              <p id="contact" style={{ fontSize: 15 }}>
                                <strong>
                                  <span data-custom-class="heading_1">
                                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
                                    DATA WE COLLECT FROM YOU?
                                  </span>
                                </strong>
                              </p>
                              <div>
                                <span data-custom-class="body_text">
                                  Based on the laws of some countries, you may
                                  have the right to request access to the
                                  personal information we collect from you,
                                  change that information, or delete it in some
                                  circumstances. To request to review, update,
                                  or delete your personal information, please{" "}
                                  <bdt className="block-component" />
                                  visit:{" "}
                                  <bdt className="question">
                                    <a
                                      href="https://app.hookactions.com/settings"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      data-custom-class="link"
                                    >
                                      https://app.hookactions.com/settings
                                    </a>
                                  </bdt>
                                  <bdt className="else-block" />. We will
                                  respond to your request within 30 days.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivacyPolicyPage
